import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {Button, TextField, Typography} from "@material-ui/core";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleUtil from "../util/YoleUtil";
import YoleToast from "../component/YoleToast";
import YoleSnackbars from "../component/YoleSnackbars";
import YwsPaymentV2Service from "../service/YwsPaymentV2Service";
import YwsPayData from "../model/YwsPayData";

interface YwsTestFastPaymentPageProp extends RouteComponentProps {

}

export class YwsTestFastPaymentPageState {
    appKey: string = "";
    amount?: number;
}

export default class YwsTestFastPaymentPage extends React.Component<YwsTestFastPaymentPageProp, YwsTestFastPaymentPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            appKey: ""
        };
        this.onPaymentClick = this.onPaymentClick.bind(this);
    }

    componentDidMount(): void {
        const href = window.location.href;
        let appKey = YoleUtil.getParameterByName("appKey", href);
        this.setState({appKey: appKey});
    }

    onBack() {
        this.props.history.goBack();
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar leftComp={<div style={{width: "20%", textAlign: "left"}}/>}
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography variant={"h5"}>Payment test</Typography>
                               </div>
                           }/>
            <div style={{padding: 12}}>
                <Typography variant={"h5"}>Payment amount</Typography>
                <TextField label="Test amount" variant="outlined"
                           value={this.state.amount} type="number"
                           style={{width: "100%", backgroundColor: "white", marginTop: 8}}
                           onChange={(event) => {
                               let value = event.target.value;
                               if (value != "") {
                                   this.setState({amount: +value})
                               } else {
                                   this.setState({amount: undefined})
                               }
                           }}/>
                <div style={{textAlign: "center", marginTop: 20}}>
                    <Button variant={"contained"} onClick={this.onPaymentClick} color={"primary"} style={{width: "100%"}}>
                        Pay
                    </Button>
                </div>
            </div>
        </div>
    }

    onPaymentClick() {
        if (this.state.amount == null || this.state.amount <= 0) {
            YoleSnackbars.showText("Please enter payment amount", 10000, "error");
            return;
        } else {
            YoleToast.showToastLoading();
            let appKey = this.state.appKey;
            if (appKey == null || appKey.trim().length == 0) {
                appKey = "81000004";
            }
            YwsPaymentV2Service.ifreePay(appKey, this.state.amount + "",
                (payData: YwsPayData) => {
                    window.location.href = payData.payUrl;
                })
        }
    }
}
