import YwsPaymentInitRecord from "../model/YwsPaymentInitRecord";
import HttpServiceV2 from "./HttpServiceV2";
import YwsPayData from "../model/YwsPayData";
import {EOperator} from "../model/DcbOperator";
import HttpService from "./HttpService";
import YwDcbPaymentStatus from "../model/YwDcbPaymentStatus";

export default class YwsPaymentV2Service {

    static getPaymentInit(id: string, successFunction: (result: YwsPaymentInitRecord) => void) {
        const uri = "/api/payment/transaction/get";
        HttpServiceV2.get(uri, {id: id}, successFunction);
    }

    static payOrder(payData: YwsPaymentInitRecord, successFunction: (payData: YwsPayData) => void) {
        const uri = "/api/payment/transaction/pay";
        HttpServiceV2.postJson(uri, payData, successFunction);
    }

    static checkCustomerMobile(mobile: string, successFunction: (result: EOperator) => void) {
        const uri = "/api/payment/dcb/checkMobile";
        HttpServiceV2.get(uri, {mobile: mobile}, successFunction, HttpService.handleError);
    }

    static getPaymentStatus(billingNumber: string, successFunction: (result: YwDcbPaymentStatus) => void) {
        const uri = "/api/payment/getPaymentStatus";
        HttpServiceV2.get(uri, {billingNumber: billingNumber}, successFunction, HttpService.handleError);
    }

    static userCancel(billingNumber: string, successFunction: () => void) {
        const uri = "/api/payment/transaction/userCancel";
        HttpServiceV2.postFormData(uri, {billingNumber: billingNumber}, successFunction, HttpService.handleError);
    }

    static testPayment(countryCode: string, amount: number, paymentMethod: string, successFunction: (url: string) => void) {
        const uri = "/api/payment/test/init";
        HttpServiceV2.postFormData(uri, {
            countryCode: countryCode,
            amount: amount,
            paymentMethod: paymentMethod,
        }, successFunction, HttpService.handleError);
    }

    static testBeelinePay(mobile: string, amount: string, successFunction: (payData: YwsPayData) => void) {
        const uri = "/api/test/payment/beeline/pay";
        HttpServiceV2.postFormData(uri, {mobile: mobile, amount: amount}, successFunction, HttpService.handleError);
    }

    static ifreePay(appKey: string, amount: string, successFunction: (payData: YwsPayData) => void) {
        const uri = "/api/test/payment/ifreePay";
        HttpServiceV2.get(uri, {appKey: appKey, amount: amount}, successFunction, HttpService.handleError);
    }

    static redirect(merchantOrderId: string) {
        const uri = "/api/redirect/dcbPayResult";
        HttpServiceV2.get(uri, {
            invoiceId: "",
            merchantOrderId: merchantOrderId
        }, () => {
        }, HttpService.handleError);
    }

    static testPaymentResults(billingNumber: string, paymentStatus: string, successFunction: (pageUrl: string) => void) {
        const uri = "/api/test/payment/callback";
        HttpServiceV2.postFormData(uri, {
            billingNumber: billingNumber,
            paymentStatus: paymentStatus
        }, successFunction, HttpService.handleError);
    }
}
